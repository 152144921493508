import React from 'react'
import mail from '../images/mail-icon.png'
import phone from '../images/phone-icon.png'


export default props => {
    return (
        <div className="grid text-sulphur text-white grid-cols-1 xl:grid-cols-2 items-center fondo-azul-mayalav h-screen">
            <div className="w-full h-full xl:h-screen">
                <iframe width="100%" height="100%" frameborder="0" 
                scrolling="no" marginheight="0" marginwidth="0" 
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Macroplaza,%20Merida+(Mayalav)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                </iframe>
                
            </div>
            <div className="text-left px-24 bg-white bg-opacity-25 rounded w-3/4 m-auto py-24 my-24 text-dosis items-center">
                <h1 className="text-5xl ">{props.titulo}</h1>
                <a className="flex items-center hover:bg-blue-700 py-2 px-4 hover:border-white" href="mailto:hola@mayalav.mx">
                    <img className="mr-2" src={mail} width="20px" alt="icon mail"/>
                    Email: hola@mayalav.mx
                </a>
                <a className="flex items-center hover:bg-blue-700 py-2 px-4 hover:border-white" href="Tel: 9995991337">
                    <img className="mr-2" src={phone} width="20px" alt="icon phone"/>
                    Tel. 999 599 1337
                </a>
                <h1 className="text-3xl pt-6 pb-2">Sucursales:</h1>
                <ul className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                    <li>Macroplaza</li>
                    <li>Dorada</li>
                    <li>Caucel</li>
                    <li>Brisas</li>
                    
                </ul>
            </div>
        </div>
    )
}