import React from 'react'
import Footer from '../components/Footer'
import SeccionA from '../components/SeccionA'
import SeccionMapa from '../components/SeccionMapa'
import Hero2 from '../components/Hero2'

const info = () => {
    return (
        <>

         <Hero2 color='blanco' 
         titulo="POR UN FUTURO LIMPIO" 
         subtitulo="PRÓXIMAMENTE servicio a domicilio"
         //parrafo="Now acceptable for formal occasions too, Doucal's leather or suede sneakers have a dressy feel and can be donned under sharp suits or blue jeans alike. With sponge insoles and naturally-treated suede, they can be worn without socks."
         />
         <SeccionA/>
         <SeccionMapa/>
         <Footer/>

        </>
    )
}

export default info
