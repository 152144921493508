import React from 'react'

const SeccionA = () => {
    return (
        <div className="py-12 grid grid-cols-1 lg:grid-cols-2 items-center h-screen md:h-64 text-gray-700">
            <div className="text-center">
                <h1 className="text-3xl">Seccion A</h1>
                <p>Columna 1</p>
            </div>
            <div className="text-center">Columna 2</div>            
        </div>
    )
}

export default SeccionA
