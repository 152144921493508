import React from 'react'
import BackgroundImg from 'gatsby-background-image'
import {graphql,useStaticQuery} from 'gatsby'
import NavBar from './NavBar'

export default (props) => {
  const data = useStaticQuery(graphql`
    {
      fixed: file(relativePath: {eq: "mayalav01.jpg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      fluid: file(relativePath: {eq: "mayalav6.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return (
        <BackgroundImg 
        className="bg-gray-500 text-white text-center grid grid-cols-1 h-screen content-center items-center shadow-lg "
        fluid={data.fluid.childImageSharp.fluid} 
        >
        <NavBar/>
        <div>
          <h1 className="text-6xl text-sulphur px-4">{props.titulo}</h1>  
          <h3 className="text-lg text-gray-800 px-4">{props.subtitulo}</h3>
          <p className="py-8 text-3xl text-white px-8">{props.parrafo}</p>
        </div>
          {/* <p>{props.children}</p> */}
        </BackgroundImg>
    )
}